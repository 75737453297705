// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.Toastify__toast--info {
  background: #333 !important;
  color: #fff !important;
  font-family: Arial, sans-serif;
  font-size: 16px !important;
  text-align: center !important;
  border: 1px solid #444 !important;
  border-radius: 8px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  padding: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;EAC1B,6BAA6B;EAC7B,iCAAiC;EACjC,6BAA6B;EAC7B,kDAAkD;EAClD,wBAAwB;AAC1B","sourcesContent":["/* src/App.css */\n.Toastify__toast--info {\n  background: #333 !important;\n  color: #fff !important;\n  font-family: Arial, sans-serif;\n  font-size: 16px !important;\n  text-align: center !important;\n  border: 1px solid #444 !important;\n  border-radius: 8px !important;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;\n  padding: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
