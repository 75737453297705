import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  useEffect(() => {
    const notify = () => {
      toast.info(
        <div>
          <p>ขออภัยในความไม่สะดวกเนื่องจากทางเรายังไม่เปิดบริการเต็มรูปแบบ หากท่านต้องการคอร์สหรือเข้าถึงเนื้อหาต่างๆ โปรดเรียนในเว็บไซต์ที่ทางเรากำลังจะ Redirect ท่านไป</p>
        </div>, {
        autoClose: 5000,
        closeOnClick: false,
        draggable: false,
        closeButton: true,
        hideProgressBar: false,
        className: 'custom-toast',
        progressClassName: 'custom-progress-bar'
      });

      setTimeout(() => {
        window.location.href = 'https://dropctf.live';
      }, 5000);
    };

    notify();
  }, []);

  return (
    <div className="App">
      <ToastContainer 
        position="top-center"
        style={{ width: '500px' }}
      />
    </div>
  );
}

export default App;
