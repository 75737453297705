// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css หรือ src/App.css */
.dark-toast {
  background: #1e1e1e !important;
  color: #fff !important;
  font-family: Arial, sans-serif;
  font-size: 18px;
  text-align: center;
  border: 1px solid #444;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.dark-toast-body {
  margin: 0;
}

.dark-progress-bar {
  background: #4caf50 !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,8BAA8B;EAC9B,sBAAsB;EACtB,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,uCAAuC;EACvC,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/* src/index.css หรือ src/App.css */\n.dark-toast {\n  background: #1e1e1e !important;\n  color: #fff !important;\n  font-family: Arial, sans-serif;\n  font-size: 18px;\n  text-align: center;\n  border: 1px solid #444;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  padding: 20px;\n}\n\n.dark-toast-body {\n  margin: 0;\n}\n\n.dark-progress-bar {\n  background: #4caf50 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
